import React from "react";

type ErrorMessageProps = {
  msg?: string;
};

const ErrorMessage = ({msg}: ErrorMessageProps) => {
  if (!msg) return null;

  return <p className="text-xs mt-1 text-error-700">{msg}</p>;
};

export default React.memo(ErrorMessage);
